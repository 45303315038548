import Image from 'next/image'
import React from 'react'

type HeroHomeImgProps = {
  classes: any
  src: any
  isMobile: boolean
  isDesktopXL: boolean
}

const HeroHomeImg = ({
  src,
  classes,
  isMobile,
  isDesktopXL,
}: HeroHomeImgProps) => {
  if (src && src.url) {
    return isMobile ? (
      <Image
        src={src['md'].url}
        className={classes}
        alt={src?.alt ? src?.alt : ''}
        layout="fill"
        priority
        objectFit="cover"
        quality={100}
      />
    ) : isDesktopXL ? (
      <Image
        src={src.url}
        className={classes}
        alt={src?.alt ? src?.alt : ''}
        layout="fill"
        priority
        objectFit="cover"
        quality={100}
      />
    ) : (
      <Image
        src={src['lg'].url}
        className={classes}
        alt={src?.alt ? src?.alt : ''}
        layout="fill"
        priority
        objectFit="cover"
        quality={100}
      />
    )
  } else {
    return null
  }
}

export default HeroHomeImg
